// @vue/component

import * as CalendarItemOption from '../../enums/CalendarItemOption';
import ModalManager from '../../services/modalManager';
import DialogManager from '../../services/DialogManager';
import doCovCheck from '../../services/covCheck';
import moment from 'moment/moment';
import dateParser from '../../i18n/dateParser';
import * as CalendarMode from '../../enums/CalendarMode';
import * as CalendarFilterMode from '../../enums/CalendarFilterMode';
import updateQueryStringParameter from '../../services/updateQueryStringParameter';
import dateFormatter from '../../i18n/dateFormatter';
import AssertsEventType from './AssertsEventType';
import jsonApi from '../../json-api-client';
import { createNamespacedHelpers } from 'vuex';
import { handleIgnorableErrorsResponse } from '../../services/ignorableErrors';

const { mapActions } = createNamespacedHelpers('calendar');

export default {
    mixins: [AssertsEventType],

    methods: {
        ...mapActions([
            'deleteEvent',
            'deleteNote',
            'deleteRecurringEvent',
            'updateEvent',
            'copyEvent',
        ]),

        fetchEvent(id) {
            return jsonApi.find('calendar_item', id).then(response => response.data);
        },

        ['action-' + CalendarItemOption.OPEN](event, eventData) {
            ModalManager.openModal(`/calendar/appointment/${event.extendedProps.apiId}/edit`, undefined, undefined, 'large');
        },
        ['action-' + CalendarItemOption.OPEN_GROUP](event, eventData) {
            ModalManager.openModal(`/calendar/appointment/${event.extendedProps.apiId}/edit#group`, undefined, undefined, 'large');
        },
        ['action-' + CalendarItemOption.DELETE](event, eventData) {
            DialogManager.confirm(this.$t('calendar.delete-confirmation'))
                .then(() => {
                    return this.deleteEvent(event.extendedProps.apiId);
                }, () => {
                    // User canceled, all ok
                });
        },
        ['action-' + CalendarItemOption.DELETE_RECURRENCES](event, eventData) {
            DialogManager.confirm(this.$t('calendar.delete-recurrences-confirmation', { date: this.$ds(event.start) }))
                .then(() => {
                    return this.deleteRecurringEvent(event.extendedProps.apiId);
                }, () => {
                    // User canceled, all ok
                });
        },
        ['action-' + CalendarItemOption.CUT](event, eventData) {
            this.$store.commit('calendar/setActiveMutation', {
                type: CalendarItemOption.CUT,
                id: event.extendedProps.apiId,
            });
        },
        ['action-' + CalendarItemOption.COPY](event, eventData) {
            this.$store.commit('calendar/setActiveMutation', {
                type: CalendarItemOption.COPY,
                id: event.extendedProps.apiId,
            });
        },
        ['action-' + CalendarItemOption.OPEN_FYSIOROADMAP](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            window.location.href = `/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/dashboard?show_notes=1`;
        },
        ['action-' + CalendarItemOption.EDIT_CONTACT_MOMENT](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/contact_moments/${participation.id}/edit?show_notes=1`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.EDIT_PATIENT](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/patient/${participation.patient_id}/general`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.EDIT_PATIENT_SUBSCRIPTION](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/patient/${participation.patient_id}/subscriptions/${participation.subscription_item_data_id}/edit`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.EDIT_PATIENT_POLICY](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/patient/${participation.patient_id}/policies`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.SHOW_PATIENT_POLICY](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/patient/${participation.patient_id}/policy/summary`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.COV](event, eventData) {
            const participation = eventData.calendar_item_participations[0];

            doCovCheck(participation.patient_id);
        },
        ['action-' + CalendarItemOption.EDIT_INDICATION_HISTORY](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/fysioroadmap/${participation.patient_id}/${participation.indication_history_id}/indication`, undefined, undefined, 'full');
        },
        ['action-' + CalendarItemOption.UNFULFILLED](event, eventData) {
            this.updateEvent({ event, apiEvent: { is_unfulfilled: true } })
                .catch(errors => DialogManager.errors(errors));
        },
        ['action-' + CalendarItemOption.OPEN_PAYMENT_STATUS](event, eventData) {
            const participation = eventData.calendar_item_participations[0];
            ModalManager.openModal(`/patient/${participation.patient_id}/participation_payment_status?type=treatment&indication_history_id=${participation.indication_history_id}`, undefined, undefined, 'full');
        },
        async ['action-' + CalendarItemOption.PASTE](event, eventData) {
            eventData = await this.fetchEvent(this.$store.state.calendar.activeMutation.id);

            const updateEvent = {
                extendedProps: {
                    apiId: this.$store.state.calendar.activeMutation.id,
                },
            };
            const apiEvent = {};
            let resourceId = this.$store.state.calendar.filter.value[0];

            if (event && this.eventIsPlaceholder(event)) {
                updateEvent.start = moment(event.start);
                updateEvent.end = moment(event.end);
            } else {
                if (!this.$options.contextClickInfo) {
                    return;
                }

                const originalStart = moment(dateParser(eventData.start));
                const originalEnd = moment(dateParser(eventData.end));
                const originalDiff = originalEnd.diff(originalStart);

                updateEvent.start = moment(this.$options.contextClickInfo.date);
                if (this.mode === CalendarMode.MONTH) {
                    // In month view we leave the time just 'as is' because the user can't select a time.
                    updateEvent.start.set({
                        hour: originalStart.hour(),
                        minute: originalStart.minute(),
                        second: originalStart.second(),
                    });
                }
                updateEvent.end = updateEvent.start.clone().add(originalDiff);

                if (this.showResources) {
                    resourceId = this.$options.contextClickInfo.resource ? this.$options.contextClickInfo.resource.id : undefined;
                }
            }

            if (event && (this.eventIsPlaceholder(event) || this.eventIsScheduleBackground(event))) {
                // Copy some properties from the placeholder
                if (event.extendedProps.employees.length) {
                    apiEvent.employees = event.extendedProps.employees.map(id => ({ id }));
                }

                const hasLocationChange = event.extendedProps.locationId !== eventData.location_id;
                apiEvent.location = { id: String(event.extendedProps.locationId) };

                if (event.extendedProps.locationRooms.length) {
                    apiEvent.location_rooms = event.extendedProps.locationRooms.map(id => ({ id }));
                } else if (hasLocationChange) {
                    apiEvent.location_rooms = [];
                }

                if (event.extendedProps.locationDevices.length) {
                    apiEvent.location_devices = event.extendedProps.locationDevices.map(id => ({ id }));
                } else if (hasLocationChange) {
                    apiEvent.location_devices = [];
                }

                if (this.showResources) {
                    resourceId = event.resourceIds.length ? event.resourceIds[0] : undefined;
                }
            }

            if (resourceId) {
                switch (this.$store.state.calendar.filter.mode) {
                    case CalendarFilterMode.EMPLOYEES:
                        apiEvent.employees = [{ id: resourceId }];
                        break;
                    case CalendarFilterMode.ROOMS:
                        apiEvent.location = { id: String(this.$store.getters['calendar/roomById'](resourceId).location_id) };
                        apiEvent.location_rooms = [{ id: resourceId }];
                        if (eventData.location_id !== apiEvent.location.id) {
                            apiEvent.location_devices = [];
                        }
                        break;
                    case CalendarFilterMode.DEVICES:
                        apiEvent.location = { id: String(this.$store.getters['calendar/deviceById'](resourceId).location_id) };
                        apiEvent.location_devices = [{ id: resourceId }];
                        if (eventData.location_id !== apiEvent.location.id) {
                            apiEvent.location_rooms = [];
                        }
                        break;
                }
            }

            if (this.$store.state.calendar.activeMutation.type === CalendarItemOption.CUT) {
                try {
                    await this.updateEvent({ event: updateEvent, apiEvent });
                } catch (errors) {
                    try {
                        await handleIgnorableErrorsResponse(errors, () => this.updateEvent({
                            event: updateEvent,
                            apiEvent,
                            force: true,
                        }));
                    } catch (errors) {
                        await DialogManager.errors(errors);
                    }
                }

                this.$store.commit('calendar/setActiveMutation', null);
            } else if (this.$store.state.calendar.activeMutation.type === CalendarItemOption.COPY) {
                await this.copyEvent({ event: updateEvent, apiEvent });
            }
        },
        ['action-' + CalendarItemOption.APPOINTMENT](event, eventData) {
            if (event) {
                let url = `/calendar/appointment/create?start=${event.start}&end=${event.end}&location=${event.extendedProps.locationId}&${this.$store.state.calendar.filter.mode}=${this.$store.state.calendar.filter.value}`;

                if ((this.eventIsPlaceholder(event) || this.eventIsScheduleBackground(event)) && event.extendedProps.locationRooms.length) {
                    url = updateQueryStringParameter(url, 'rooms', event.extendedProps.locationRooms.join());
                }

                ModalManager.openModal(url, undefined, undefined, 'large');
            } else {
                if (this.$options.contextClickInfo) {
                    const start = moment(this.$options.contextClickInfo.date).subtract(moment(this.$options.contextClickInfo.date).minutes() % 15, 'minutes');

                    ModalManager.openModal(`/calendar/appointment/create?start=${dateFormatter(start)}&${this.$store.state.calendar.filter.mode}=${this.$options.contextClickInfo.resource?.id || this.$store.state.calendar.filter.value}`, undefined, undefined, 'large');
                } else {
                    ModalManager.openModal(`/calendar/appointment/create?${this.$store.state.calendar.filter.mode}=${this.$store.state.calendar.filter.value}`, undefined, undefined, 'large');
                }
            }
        },
        ['action-' + CalendarItemOption.NOTE](event) {
            if (this.$options.contextClickInfo) {
                const start = moment(this.$options.contextClickInfo.date).subtract(moment(this.$options.contextClickInfo.date).minutes() % 15, 'minutes');

                ModalManager.openModal(`/calendar/note/create?date=${dateFormatter(start)}&${this.$store.state.calendar.filter.mode}=${this.$options.contextClickInfo.resource?.id || this.$store.state.calendar.filter.value}`, undefined, undefined, 'large');
            } else {
                ModalManager.openModal(`/calendar/note/create?${this.$store.state.calendar.filter.mode}=${this.$store.state.calendar.filter.value}`, undefined, undefined, 'large');
            }
        },
        ['action-' + CalendarItemOption.EDIT_NOTE](event) {
            ModalManager.openModal(`/calendar/note/${event.extendedProps.apiId}/edit`, undefined, undefined, 'large');
        },
        async ['action-' + CalendarItemOption.DELETE_NOTE](event) {
            await DialogManager.confirm(this.$t('calendar.delete-note-confirmation'));

            this.deleteNote(event.extendedProps.apiId);
        },
    },
};
